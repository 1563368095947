<template>
  <f7-link @click="navigateNow" v-if="$configs.chatEnabled">
    <div style="position: relative">
      <f7-badge v-if="Unread && !orderkey && !productkey" class="badge-chat" color="blue" style="right: -18px">{{ Unread }}</f7-badge>

      <div v-if="orderkey || productkey">
        <font-awesome-icon :icon="['far', 'comment']" fixed-width /> <span style="margin-left: 10px" v-if="orderkey">{{ $t.getTranslation('LBL_CHAT_NOW') }}</span>
      </div>

      <div v-else class="con-text-box" style="cursor: pointer">
        <img :src="require('@/assets/images/chat-icon02.png')" style="width: 40px; position: relative; left: 2px" loading="lazy" />
        <div class="con-text-holder">
          <p>{{ $t.getTranslation('LBL_LIVE_TEXT') }}</p>
          <p style="color: #0c4d8f">{{ $t.getTranslation('LBL_CHAT_TEXT') }}</p>
        </div>
      </div>
    </div>

    <!-- <img v-else :src="getChatImage($t.getLanguage())" style="width:50px;margin-top:5px;"> -->
  </f7-link>
</template>

<script>
import { defineComponent, inject, computed } from 'vue'
import { post } from '@/utils/axios'
import { useStore } from '@/store'
import { f7 } from "framework7-vue";

export default defineComponent({
  name: 'ChatNavigationComponent',
  components: {},
  props: {
    orderkey: {
      type: String,
      default: ''
    },
    productkey: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore();
    
   const $f7router = f7.views.get("#main-view").router;
    const Unread = computed(() => store.getters['chat/getData']?.Unread)

    let navigateNow = async () => {
      let Source = null,
        location = window.location.href

      if (location && location.indexOf('/product/view') > -1) {
        Source = 'product'
      } else if (location && location.indexOf('/order/view') > -1) {
        Source = 'order'
      } else if (location && location.indexOf('/profile') > -1) {
        Source = 'profile'
      } else {
        Source = 'shop'
      }

      let query = { utm_source: Source }
      if (props?.orderkey) {
        query.order = props.orderkey
      }
      if (props?.productkey) {
        query.product = props.productkey
      }

      await post('/chat/source', { Source })

      $f7router.navigate({ name: 'chat', query: query })
    }

    let getChatImage = (lang) => {
      return lang == 'EN' ? require('@/assets/images/chat_en.png') : require('@/assets/images/chat_ch.png')
    }
    return {
      navigateNow,
      Unread,
      getChatImage
    }
  }
})
</script>

<style scoped>
.con-text-box {
  display: flex;
  align-items: center;
  padding-right: 15px;
}
.con-text-holder {
  font-size: 10px;
  text-align: center;
  line-height: 13px;
  font-weight: 700;
}
.con-text-holder p {
  margin: 0px;
}
.badge-chat {
  position: absolute;
  top: 4px;
  right: 4px;
}
</style>
